import React, { FC, useEffect, useMemo, useState } from "react";
import MovieDescription from "../../../../../components/TicketBooking/MovieDescription";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import {
  IFormatedTicketType,
  ISeatLayout,
  ITicketType,
  MODAL_TYPE,
  TICKET_FLOW_TYPES,
} from "../../../../../models/tickets";
import { API_REQUEST_TYPE, URLS } from "../../../../../constants/url";
import { COUNTRY, MEMBERSHIP_TYPE } from "../../../../../constants/app";
import "./seatSelection.scss";
import {
  setPopupInfo,
  setReinitializeScroll,
} from "../../../../../store/application/action";
import { TICKET_FLOW } from "../../../../../constants/ticket";
import { checkSeat, generateUsersessionId } from "../../../../../utils/tickets";
import { formatUserDetails } from "../../../../../utils/formatter";
import {
  addTicketSuccess,
  cancelOnlyTicketAction,
  setModal,
  toggleTicketModal,
  updateSession,
} from "../../../../../store/ticketBooking/action";
import { addTicketApi } from "../../../../../services/films";
import { setUpModalHeaderText } from "../../../../../store/foodAndBeverage/action";
import { toast } from "react-toastify";
import { ISharedUserSession } from "../../../../../store/ticketBooking/type";
import { useLocation, useNavigate } from "react-router";
import SwapFilter from "../../../../../components/UI/Filters/SwapFilter";
import { memberValidationApi } from "../../../../../services/auth";
import { memberValidationSuccess } from "../../../../../store/auth/action";
import { formatMembershipType } from "../../../../../utils/rewards";
import { ROUTES } from "../../../../../constants/path";
import { pushDataLayer } from "../../../../../utils/dataLayer";

interface ISeatSelectionProps {
  onCancel: (error?: string) => void;
}

const SeatSelection: FC<ISeatSelectionProps> = ({ onCancel }) => {
  const {
    countryId,
    modalType,
    nextModal,
    ticketBooking,
    settings,
    films,
    showCovid,
    userDetails,
    isLoggedIn,
    hasGL,
    ticketDetails,
    prevModal,
    sharedUserSession,
    seatSwapDetails,
    currentCinema,
    ticketLessFb,
    memberDetail
  } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    modalType: state.ticketBookingReducer.modal?.type,
    nextModal: state.ticketBookingReducer.modal?.nextModal,
    ticketBooking: state.ticketBookingReducer,
    settings: state.applicationReducer.settings,
    films: state.ticketBookingReducer.films,
    showCovid: state.ticketBookingReducer.showCovid,
    userDetails: state.authReducer.userDetails,
    isLoggedIn: state.authReducer.isLoggedIn,
    hasGL: state.ticketBookingReducer.hasGL,
    ticketDetails: state.ticketBookingReducer.ticketDetails,
    prevModal: state.ticketBookingReducer.modal.prevModal,
    sharedUserSession: state.ticketBookingReducer.sharedUserSession,
    seatSwapDetails: state.ticketBookingReducer.seatSwapDetails,
    currentCinema: state.applicationReducer.currentCinema,
    ticketLessFb: state.foodBeverageReducer.ticketLessFb,
    memberDetail: state.authReducer.memberDetail,
  }));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [seatLayout, setSeatLayout] = useState<Array<ISeatLayout[]>>([]);
  const [selectedSeat, setSelectedSeat] = useState<Array<any>>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    if (
      ticketDetails &&
      ticketDetails.selectedSeats &&
      ticketDetails.selectedSeats.length > 0 &&
      selectedSeat.length === 0 &&
      seatLayout.length > 0
    ) {
      // setSelectedSeat(ticketDetails.selectedSeats);
      // setCount(
      //   ticketDetails.selectedSeats && ticketDetails.selectedSeats.length
      //     ? ticketDetails.selectedSeats.length
      //     : 0
      // );

      // setSeatLayout(ticketDetails.seatLayout);
    }
  }, [ticketDetails, selectedSeat, seatLayout]);

  useEffect(() => {
    if (
      ticketBooking?.seatLayout &&
      ticketBooking?.seatLayout?.length > 0 &&
      ticketBooking.ticketType
    ) {
      let seatLayoutCopy = JSON.parse(JSON.stringify(ticketBooking.seatLayout));
      let countCopy = 0;
      let selectSeatCopy: Array<any> = [];

      if (
        ticketDetails &&
        ticketDetails.selectedSeats &&
        ticketDetails.selectedSeats.length > 0
      ) {
        selectSeatCopy = ticketDetails.selectedSeats;
        countCopy = ticketDetails.selectedSeats.length;
        seatLayoutCopy = seatLayoutCopy.map((v: ISeatLayout[]) => {
          return v.map((s: ISeatLayout) => {
            if (
              selectSeatCopy.find(
                (seat: ISeatLayout) => seat.SeatId === s.seatId
              )
            ) {
              s["isBooked"] = true;
              s.isSelected = true;
            }
            return s;
          });
        });

        setSelectedSeat(selectSeatCopy);
      }
      setSeatLayout(seatLayoutCopy);
      setCount(countCopy);
      setErrorMessage("");
      dispatch(setReinitializeScroll(true));
    } else {
      onCancel("Tickets not available for this session");
      return;
    }
  }, [ticketBooking.seatLayout, ticketBooking.ticketType]);

  const covidSeatStatus = useMemo(() => {
    return (
      seatLayout.filter((layoutResult) => {
        return layoutResult.some(
          (layoutChlid) => layoutChlid.seatType === "Covid19"
        );
      }).length > 0
    );
  }, [seatLayout]);

  const { bookingFees, total, price } = useMemo(() => {
    if (
      modalType === TICKET_FLOW_TYPES.MULTI_TICKETING ||
      modalType === TICKET_FLOW_TYPES.MULTI_TICKETING_FB
    ) {
      const bookingFees = ticketBooking.ticketDetails?.bookingFees
        ? Number(ticketBooking.ticketDetails?.bookingFees)
        : 0;
      const total = ticketBooking.ticketDetails?.total
        ? Number(ticketBooking.ticketDetails?.total)
        : 0;

      const price = ticketBooking.ticketDetails?.price
        ? Number(ticketBooking.ticketDetails?.price)
        : 0;
      return { bookingFees, total, price };
    } else {
      const bookingFees =
        selectedSeat.length *
        (hasGL === 1
          ? settings.glServiceChargeFee!  :(films.sessionAttributes === "Soho"? settings.sohoServiceChargeFee!:
          settings.serviceChargeFee!));

      const price =
        ticketBooking &&
        ticketBooking.ticketType &&
        ticketBooking.ticketType.length > 0
          ? Number(ticketBooking.ticketType[0].Price)
          : 0;
      const total =
        selectedSeat.length * price +
        selectedSeat.length *
          (hasGL === 1
            ? settings.glServiceChargeFee! : (films.sessionAttributes === "Soho"? settings.sohoServiceChargeFee
            : settings.serviceChargeFee!));

      return { bookingFees, total, price };
    }
  }, [selectedSeat, ticketBooking.ticketDetails]);

  const onClickSeat = (event: any, parentIndex: number, childIndex: number) => {
    setErrorMessage("");
    if (
      event &&
      (selectedSeat.length >= 10)
    ) {
      setErrorMessage(`You have selected the max tickets`);

      return;
    }
    const seatLayoutCopy = [...seatLayout];
    const seatSelected = [...selectedSeat];
    const seatObj = seatLayoutCopy[parentIndex][childIndex];
    if (seatObj.seatType === "Covid19") {
      dispatch(setPopupInfo({ open: true, type: "COVID-19" }));
      return true;
    }

    let seatGap = false;

    seatObj["isBooked"] = false;

    if (seatObj.seatType === "Aisle") {
      return true;
    }
    if (seatObj.seatType === "Empty") {
      seatGap = true;
    }

    if ((!seatObj.isBooked && !seatObj.isAvailable) || seatObj.isBooked) {
      return false;
    }

    if (seatObj.isSelected) {
      seatObj.isSelected = false;
      setCount(count - 1);
      seatSelected.splice(
        seatSelected.findIndex((val) => val.SeatId === seatObj.seatId),
        1
      );
    } else if (event) {
      seatObj["isBooked"] = true;
      const updateCount = count + 1;
      setCount(updateCount);
      seatObj.isSelected = true;
      seatSelected.push({
        SeatId: seatObj.seatId,
        AreaNumber: seatObj.areaNumber,
        RowIndex: seatObj.row,
        ColumnIndex: seatObj.column,
        AreaCategoryCode: ticketBooking.ticketType[0].AreaCategoryCode,
        ParentIndex: parentIndex,
        ChildIndex: childIndex,
      });
      seatLayoutCopy[parentIndex][childIndex] = seatObj;
    }
    setSelectedSeat(seatSelected);
    setSeatLayout(seatLayoutCopy);

    if (seatObj.seatType === "Special" && seatObj.isSelected) {
      dispatch(setPopupInfo({ open: true, type: "Special" }));
    }
  };

  const onNext = async () => {
    setErrorMessage("");
    if (selectedSeat.length === 0) {
      setErrorMessage("No Seats Selected");
      return;
    }
    dispatch(setUpModalHeaderText("Add Food & Drink"));

    if (settings.enableSeatGap === 1) {
      const checkSeatError = checkSeat(selectedSeat, seatLayout);
      if (checkSeatError.error === 1) {
        setErrorMessage(
          "You have left a single seat gap on the aisle, please adjust your seating choice so no gaps remain."
        );
        return;
      }
    }

    const payload = {
      cinemaId: films?.cinemaId,
      countryId: films?.countryId,
      hasGL: ticketBooking.hasGL,
      hasSoho: films.sessionAttributes === "Soho"? 1:0,
      movieId: films?.movieId,
      requestType: API_REQUEST_TYPE.ADD_TICKET,
      reservedSeating: 1,
      selectedSeats: selectedSeat,
      sessionId: films?.sessionId,
      ticketType: ticketBooking.ticketType[0].Description,
      ticketTypes:modalType === TICKET_FLOW_TYPES.MULTI_TICKETING ||
        modalType === TICKET_FLOW_TYPES.MULTI_TICKETING_FB
        ? ticketBooking.ticketDetails?.ticketTypes?.map((v: ITicketType) => {
          return { ...v, Description: v.description ? v.description : "" };
        })
        : ticketBooking.ticketType.map((v: ITicketType) => {
          const value = {
            Description: v.Description ? v.Description : "",
            qty: selectedSeat.length,
            bookingFees:
              Number(
                hasGL === 1
                  ? settings.glServiceChargeFee! : (films.sessionAttributes === "Soho" ? settings.sohoServiceChargeFee
                    : settings.serviceChargeFee!)
              ) * selectedSeat.length,
            count: selectedSeat.length,
            price: v.Price,
            ticketTypeCode: v.TicketTypeCode,
          };
          return value;
        }),
      userDetials:
        userDetails && userDetails?.result?.memberRefId!
          ? formatUserDetails(userDetails, countryId, films?.cinemaId!)
          : {},
      userSessionId: ticketDetails?.userSessionId,
      isMobile: 0,
      userAgent: navigator.userAgent,
    };

    const ticketType: TICKET_FLOW_TYPES = modalType;
    const next: MODAL_TYPE = nextModal;
    const modal = TICKET_FLOW[ticketType][next];

    if (modalType === TICKET_FLOW_TYPES.MULTI_TICKETING ||
      modalType === TICKET_FLOW_TYPES.MULTI_TICKETING_FB) {
      dispatch(
        addTicketSuccess({
          userSessionId: ticketDetails?.userSessionId,
          selectedSeats: payload.selectedSeats,
        })
      );
      dispatch(setModal({ ...modal, type: modalType }));
      return;
    }

    const ticketResponse = await addTicketApi(payload);
    const {
      data: {
        data: { error, saleId },
      },
    } = ticketResponse;
    if (error) {
      toast.error(
        error === "SeatsUnavailable"
          ? "There was an error in booking your ticket(s): Seat not available"
          : "There was an error in booking your ticket(s)"
      );
      return;
    }

    dispatch(
      addTicketSuccess({
        total,
        bookingFees,
        price,
        reservedSeating: payload.reservedSeating,
        selectedSeats: payload.selectedSeats,
        ticketTypes: payload.ticketTypes ? payload.ticketTypes : [],
        userSessionId: saleId,
      })
    );
    dispatch(setModal({ ...modal, type: modalType }));
    pushDataLayer({
      isLoggedIn,
      films,
    }, 'add_to_cart')
  };

  const updateMemberdetails = async (userSessionId: string) => {
    if (
      isLoggedIn &&
      !ticketLessFb &&
      userDetails?.cardNumber
    ) {
      const memberResponse: any = await memberValidationApi({
        MemberRefId: userDetails?.vista_memberid,
        UserSessionId: userSessionId,
        cardNumber: userDetails?.cardNumber,
        countryId: countryId,
      });

      if (memberResponse.status && memberResponse.response.data && memberResponse.response.data.data) {
        dispatch(memberValidationSuccess(memberResponse.response.data.data));
      }
    }
  }

  const onPrev = async () => {
      onCancel();
  };

  const isSeatSwap = useMemo(() => {
    return pathname.includes("/seat-swap/") && seatSwapDetails ? true : false;
  }, [pathname, seatSwapDetails]);

  const legend = useMemo(() => {
    return seatLayout ? [...new Set(seatLayout.flat().map(v => v.seatType))] : []
  }, [seatLayout])

  const totalTicketQty = useMemo(() => {
    if (
      modalType === TICKET_FLOW_TYPES.MULTI_TICKETING ||
      modalType === TICKET_FLOW_TYPES.MULTI_TICKETING_FB
    ) {
      if (ticketBooking?.ticketDetails?.ticketTypes) {
        return ticketBooking?.ticketDetails?.ticketTypes.reduce((acc: number, v: IFormatedTicketType) => {
          acc = acc + v.qty
          return acc;
        }, 0)
      } else {
        return 0
      }
    }else{
      return selectedSeat.length
    }
  
  }, [ticketBooking, selectedSeat])

  const membershipType = useMemo(() => {
    return formatMembershipType(memberDetail!)
  }, [memberDetail])

  const goToMembership = () => {
    navigate(`${ROUTES.MEMBERSHIPS}`);
    dispatch(toggleTicketModal(false))
  }

  const promo = useMemo(() => {
    switch (countryId) {
      case COUNTRY.ANG:
        return "Save up to 20% on tickets when you become a Angelika Rewards Member!"
      case COUNTRY.STA:
        return "Save up to 15% on tickets when you become a Angelika Rewards Member!"
      default:
        return "Save up to 25% on tickets when you become a Reel Club Member!";
    }
  }, countryId)

  const isMember = useMemo(()=>{
    return memberDetail && memberDetail.LoyaltySessionToken?true: false;
  },[memberDetail]);
  
  const showLink = useMemo(()=>{
    return (!isLoggedIn || !isMember) && countryId === COUNTRY.STA && films?.sessionId === "111171";
  },[isLoggedIn, isMember, countryId, films])

  return (
    <div className="row seat-selection-wrapper">
      <MovieDescription />
      {settings.enableTicketBooking == 0 ? (
        <div className="col-md-8 bookingnotallowed">
          Online ticket booking not available
        </div>
      ) : (
        <div className="col-12 col-md-12 col-lg-8 p-l-10">
        {showLink? <div className="membership-promo">
                  <span>Join Angelika Rewards today or Sign in to your account here to access tickets</span>
                  <button className="btn btn-sm black_btn" onClick={goToMembership}>JOIN NOW</button>
                </div>:null}
          {modalType === TICKET_FLOW_TYPES.MULTI_TICKETING ||
            modalType === TICKET_FLOW_TYPES.MULTI_TICKETING_FB ? (<>
              {(countryId === COUNTRY.ANG || countryId === COUNTRY.STA) && !showLink && membershipType === MEMBERSHIP_TYPE.CLUB && !isLoggedIn ?
                <div className="membership-promo">
                  <span>{promo}</span>
                  <button className="btn btn-sm black_btn" onClick={goToMembership}>JOIN NOW</button>
                </div>
                : null}
              {isLoggedIn ? <div className="welcome-title">
                Welcome back, {userDetails?.firstName}.
              </div> : null}
              <div className="col-12 choose-your-seat">
                Choose your seats.
              </div>
            </>
          ) : null}
          {modalType !== TICKET_FLOW_TYPES.MULTI_TICKETING &&
          modalType !== TICKET_FLOW_TYPES.MULTI_TICKETING_FB &&
          sharedUserSession &&
          sharedUserSession.length > 0 ? (
            <div className="col-12 socialSharing">
              <span>{sharedUserSession[0].userName}</span> is sharing their
              seats with you!
            </div>
          ) : null}
          {isSeatSwap &&  modalType === TICKET_FLOW_TYPES.SEAT_SELECTION? (
            <SwapFilter />
          ) : isSeatSwap ?<div>
            <h5 className="mb-4">Select seats to swap to:</h5>
          </div>:null}
          <div className="whole-seats">
            <div className="seats">
              <ul>
                <li className="avilable">Available</li>
                <li className="unavilable">Unavailable</li>
                <li className="selected">Selected</li>
                {sharedUserSession && sharedUserSession.length > 0 ? (
                  <li className="seat-shared">Shared</li>
                ) : null}
                {covidSeatStatus ? (
                  <li className="covid">Covid-19 Seat Gap</li>
                ) : null}
              </ul>
            </div>
            <div className="screen-image">
              <img src={URLS.SCREEN_VIEW} alt="Screen" title="Screen" />
            </div>
            <div className="seat_layout_wrap">
              <div className="seat_layout">
                <div
                  className="mCustomScrollbar custom_scroll_content"
                  id="seatSelectionLayoutScrollbar"
                >
                  <table aria-describedby="seat selection">
                    <thead>
                      {seatLayout.map((seatRowObj, i) => (
                        <tr>
                          {seatRowObj.map((seatObj, j) => (
                            <th id={`head_${j}`}></th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody>
                      {seatLayout.map((seatRowObj, parentIndex) => (
                        <tr>
                          {seatRowObj.map((seatObj, first) => (
                            <>
                              {countryId === COUNTRY.STA && first === 0 ? (
                                <td className="cust-ticker-order-right">
                                  {seatObj.PhysicalName}
                                </td>
                              ) : null}
                            </>
                          ))}
                          {seatRowObj.map((seatObj, childIndex) => (
                            <td>
                              <input type="checkbox" className="checkbox-cls" />
                              <span
                                className={`checkmark
                        ${
                          !seatObj.isBooked &&
                          !seatObj.isAvailable &&
                          seatObj.seatType != "Broken" &&
                          seatObj.seatType != "Covid19"
                            ? "empty"
                            : ""
                        }
                        ${
                          seatObj.isBooked && !seatObj.isAvailable
                            ? "in-active"
                            : ""
                        }
                        ${seatObj.isSelected ? "active" : ""}
                        ${
                          seatObj.isAvailable && seatObj.seatType == "Special"
                            ? "wheel-chair"
                            : ""
                        }
                        ${seatObj.seatType == "Companion" ? "companion" : ""} 
                        ${seatObj.seatType == "Covid19" ? "covid19" : ""}
                        ${
                          !seatObj.isAvailable &&
                          !seatObj.isBooked &&
                          seatObj.seatType == "Broken"
                            ? "broken"
                            : ""
                        }               
                        ${
                          !seatObj.isAvailable &&
                          !seatObj.isBooked &&
                          seatObj.seatType == "Placeholder"
                            ? "placeholder"
                            : ""
                        }
                        ${
                          sharedUserSession &&
                          sharedUserSession.length > 0 &&
                          sharedUserSession.find((v: ISharedUserSession) =>
                            v.seats.split(",").includes(seatObj.seatId!)
                          )
                            ? "shared-seat"
                            : ""
                        }
                       
                        `}
                                onClick={() =>
                                  onClickSeat(
                                    !seatObj["isSelected"],
                                    parentIndex,
                                    childIndex
                                  )
                                }
                              ></span>
                            </td>
                          ))}
                          {seatRowObj.map((seatObj, last) => (
                            <>
                              {countryId === COUNTRY.STA &&
                              last === seatRowObj.length - 1 ? (
                                <td className="cust-ticker-order-left">
                                  {seatObj.PhysicalName}
                                </td>
                              ) : null}
                            </>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {errorMessage ? (
              <div className="error_message">
                <span>{errorMessage}</span>
              </div>
            ) : null}
            {seatLayout[0] && seatLayout[0].length > 16 ? (
              <div className="scroll-seats">Scroll to see more seats</div>
            ) : null}

            {sharedUserSession && sharedUserSession.length > 0 ? (
              <div className="seat_count">
                <div className="seatnames_list">
                  <ul>
                    <li>{sharedUserSession[0].userName}'S SEATS :</li>
                    {sharedUserSession.map((v: ISharedUserSession) => (
                      <li className="m-0">{v.seats}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ) : null}

            <div className="seat_count">
              <div className="seatnames_list">
                {/* {selectedSeat.length > 0 ? (
                  <ul>
                    <li>
                      {sharedUserSession && sharedUserSession.length > 0
                        ? "YOUR "
                        : ""}
                      SEATS :
                    </li>
                    {selectedSeat.map((v) => (
                      <li>{v.SeatId}</li>
                    ))}
                  </ul>
                ) : null} */}
                {selectedSeat.length > 0 ? (
                  <div>
                    <div className="selected-seat">{selectedSeat.length} seats selected</div>
                    <ul className="seat-name">
                      {selectedSeat.map((v, i) => (
                        <li>{v.SeatId}{i < (selectedSeat.length - 1) && <span className="comma">,</span>}</li>
                      ))}
                    </ul>
                  </div>
                ) : <div className="no-seat-selected">0 seats selected</div>}
              </div>
            </div>
          </div>
          <div className="form_page">
            <div className="user-pay-details">
            {modalType !== TICKET_FLOW_TYPES.MULTI_TICKETING &&
                modalType !== TICKET_FLOW_TYPES.MULTI_TICKETING_FB ?
              <div className="col-md-12">
                {isLoggedIn ? (
                  <>
                    <div className="welcome"> Welcome back </div>
                    <div className="my-account text-capti font-size-16">
                      {userDetails?.firstName}
                    </div>
                  </>
                ) : null}
                {isSeatSwap ? (
                  <div className="user-details">
                    <div className="row">
                      <div className="col-12 col-md-12">
                        <div className="total">REFUND TOTAL</div>
                        <div className="total-amount">
                          ${(seatSwapDetails.totalAmount / 100).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="user-details">
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div className="total">
                        {isSeatSwap ? "NEW TOTAL" : "TOTAL ORDER"}
                      </div>
                      <div className="total-amount">${total.toFixed(2)}</div>
                      {modalType === TICKET_FLOW_TYPES.MULTI_TICKETING ||
                      modalType === TICKET_FLOW_TYPES.MULTI_TICKETING_FB ? (
                        <>
                          {ticketBooking?.ticketDetails?.ticketTypes?.map(
                            (v: IFormatedTicketType) =>
                              v.qty > 0 ? (
                                <div>
                                  <div className="total-det">
                                    {v.qty} x {v.description} SEATS: $
                                    {(v.qty * v.price).toFixed(2)}
                                  </div>
                                </div>
                              ) : null
                          )}
                        </>
                      ) : selectedSeat.length > 0 ? (
                        <div className="total-det">
                          {selectedSeat.length} SEATS: $
                          {Number(ticketBooking?.ticketType[0]?.Price * selectedSeat.length).toFixed(
                            2
                          )}
                        </div>
                      ) : null}
                      {totalTicketQty > 0 ? <div className="total-det">
                        <span>{totalTicketQty} x BOOKING FEE : ${bookingFees.toFixed(2)}</span>
                      </div> : null}
                    </div>
                  </div>
                </div>
              </div>: null}
              <div className="col-md-12 movie_footer_btn_el">
                <div className="movie_button_wrap">
                  <button
                    type="button"
                    className="btn gray_btn"
                    onClick={onPrev}
                  >
                    CANCEL
                  </button>
                  <button
                    type="button"
                    className="btn black_btn"
                    onClick={onNext}
                  >
                    NEXT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SeatSelection;
